body {
  margin: 0;
  font-family: 'Gilroy-Bold',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
}
/* @font-face {
  font-family: 'Karla Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Karla Regular'), url(./fonts/Karla/Karla-Regular.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Karla Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Karla Regular'), url(./fonts/Karla/Karla[wght].woff) format('woff');
  }
  

  @font-face {
  font-family: 'Karla Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Karla Italic'), url(./fonts/Karla/Karla-Italic.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Karla Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Karla Italic'), url(./fonts/Karla/Karla-Italic[wght].woff) format('woff');
  }
  

  @font-face {
  font-family: 'Karla Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Karla Bold'), url(./fonts/Karla/Karla-Bold.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Karla BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Karla BoldItalic'), url(./fonts/Karla/Karla-BoldItalic.woff) format('woff');
  } */