/* Small devices (tablets, 768px and up) */
@media (max-width: 576px) {
    .haveanyquestion {
        bottom: 0rem;
    }

    .numbersection {
        display: none;
    }

    .numbersectionlast {
        display: none;
    }

    .thinkaboutcrousel h3 {
        font-size: 22px !important;
    }

    .afterBefore:after {
        padding: 0 30px;
        /* Example adjustment for smaller screens */
    }

    h1 {
        /* font-size: 1.5rem !important; */
        /* Larger font size for titles on smaller screens */
    }

    .upperNavbar {
        display: none;
    }

    p {
        font-size: 14px;
    }

    .ourmissiontitle {
        padding-left: 30px;
        width: 100%;
    }

    .home-card {
        margin-top: 30px;
        width: 100%;
    }
}

@media (max-width: 575px) {
    .whatwedoImage {
        display: none !important;
    }

    .whatwedocard {
        width: 100%;
        position: unset;
        right: 0px;
        top: 50%;
        transform: unset !important;
        min-height: auto !important;
    }
}

@media (max-width: 991px) {
    .haveanyquestion {
        bottom: -1rem;
    }
    a.secondaryButton {
        color: #fff !important;
    }

    a.headerlink.HeaderNavLink.active {
        color: black;
    }

    a.headerlink.HeaderNavLink {
        color: #000000a6;
    }

    .nav-link {
        color: #000000a6;
    }

    img.bd_left_Image {
        position: absolute;
        right: 0rem;
        top: 6rem;
    }

    .keyfactorSection .carousel-control-next,
    .keyfactorSection .carousel-control-prev {
        display: none;
    }

    .What_We_Do .carousel-control-prev {
        display: none;
    }

    .What_We_Do .carousel-control-next {
        display: none;

    }

    .howitwork {
        margin-top: 3rem;
    }

    .Currency_Trading_image {
        width: 100%;
        height: auto;
        top: 24%;
        z-index: 1;
    }

    .Currency_Trading_image {
        height: auto;
    }

    .pardeep {
        background-image: none;
        padding: 0rem 0;
    }

    .secondaryButton {
        background: #000 !important;
        color: #fff;
        border: white;
        width: 100px;
        font-weight: 600;
        padding: 11px 10px;
    }

    .ourmission::before {
        width: 100%;
        /* Full width on mobile */
        border-top-right-radius: 0;
        /* Adjust if needed */
        border-bottom-right-radius: 0;
        /* Adjust if needed */
    }

    .ourmission div[class*="Col"],
    /* Target all Col classes within ourmission */
    .mission-crouselimage,
    .mission-crousel {
        position: static;
        /* Reset positioning on mobile */
        width: 100%;
        /* Full width */
        text-align: center;
        /* Center content for mobile */
    }

    .ourmission div[class*="Row"] {
        /* Adjust Row within ourmission for mobile */
        display: flex;
        flex-direction: column-reverse;
        /* Image on top, text below */
    }

    .textDiv {
        margin-left: 1rem;
    }

    .divMargin {
        margin-left: 1rem;
    }

    .VisionImages {
        width: 100%;
        height: unset !important;
        position: unset !important;
    }

    .VisionIcons {
        display: none;
    }

    .halfCircleImage {
        bottom: -15%;
    }

    .ourmission .carousel-control-next {
        right: 0%;
        top: 1rem !important;
        display: none !important;
    }

    .ourmission a.carousel-control-prev {
        position: absolute;
        left: 48rem;
        top: 1rem;
        display: none !important;
    }

    .ourmissiontitle {
        width: 100%;
    }

    .factorCardImage {
        display: none;
    }

    .howitwork {
        background-repeat: repeat;
    }

    .haveanyquestion {
        top: -38%;
    }

    .anyQuestion {
        width: 100%;
    }

    h1.Crouserheading {
        font-size: 36px;

    }

    a.headerlink.active {
        color: #000;
    }

    .counter-Span {
        font-size: 1rem;
        font-weight: 100;
        font-family: 'Gilroy-Light';
    }
    .border-right-lg-up {
        border-right: none !important;
      }
}

@media (min-width: 768px) {

    .pt-5,
    .pe-5,
    .pb-5 {
        padding-top: 2rem !important;
        padding-right: 1rem !important;
        padding-bottom: 2rem !important;
    }

}

@media (max-width: 768px) {
    /* .getStarted {
        padding-bottom: 16rem !important;
    } */
    

    .about-us {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .Crypto {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .realEstate {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .Commodity {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .Mutual_Funds {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .Equity_Trading {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .Contact_us {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    .Investment_Currency_Trading {
        height: 80vh;
        /* Adjust height for mobile devices */
        padding: 50px 0;
        /* Add some vertical padding */
        padding-top: 15rem !important;
    }

    h2 {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 991px) {
    .Currency_Trading_text {
        margin-left: 3.5rem;
    }

    .home-card {
        margin-top: 30px;
        /* Adjust for tablets and smaller devices */
    }

    h1 {
        font-size: 32px;
    }
}

@media (max-width: 1200px) {
    .carousel-caption {
        top: 19%
    }

    .carousel-caption {
        padding: 4rem 0;

    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .How_We_Work_card_Section {
        width: 15rem;
        /* Set width to 15rem */
        height: 200px;
    }

    .cardsection {
        position: absolute;
        bottom: -100px;
    }
}

@media (min-width: 991px) and (max-width: 1200px) {

    /* .bottom-card {
min-height: 144px;
    } */
    .Related_Services {
        width: 100%;
        margin-top: 12rem;
    }

    .whatwedocard {
        width: 17rem;
        min-height: auto;
    }

    .howitwork {
        background-repeat: repeat;
    }

    .carousel-caption {
        padding: 4rem 0;

    }

    .How_We_Work_card_Section {
        width: 13rem;
        /* Set width to 15rem */
        height: 200px;
    }

    .cardsection {
        position: absolute;
        bottom: -100px;
    }

    p {
        font-size: 12px;
        line-height: 2;
    }

    /* .VisionImages {
        width: 38rem;
        height: 26rem;
        position: absolute;
        left: -27rem;
    } */
    .VisionImages {
        width: 27rem;
        height: auto;
        position: absolute;
        left: -27rem;
    }

    /* .What_We_Do span.carousel-control-prev-icon {
        position: absolute;
        right: -45rem;
        bottom: 3rem;
        width: 40px;
        height: 40px;
    } */

    .VisionIcons {
        width: 191px;
        height: 219px;
    }

    .mission-crouselimage {
        top: 0%;
    }

    .overview {
        padding-bottom: 5rem;
    }

    .overview {
        padding-bottom: 3rem;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    p {
        font-size: 13px;
        line-height: 2;
    }

    .Related_Services {
        width: 100%;
        margin-top: 12rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    /* .bottom-card {
        min-height: 144px;
            } */
    .How_We_Work_card h4 {
        font-size: 1rem;
    }

    .Currency_Trading {
        padding-bottom: 3rem;
    }

    .Currency_Trading_image {
        width: 149%;
        top: 46%;
    }

    .thinkaboutcrousel {
        padding: 5rem;
    }

    .What_We_Do span.carousel-control-prev-icon {
        right: -475% !important;
        bottom: 1rem !important;
    }

    .What_We_Do span.carousel-control-next-icon {
        bottom: 1rem !important;
    }

    .whatwedocard p {
        font-size: 12px;
    }

    .whatwedocard {
        width: 16rem;
        min-height: auto;
    }

    .overview {
        padding-bottom: 3rem;
    }

    h1 {
        font-size: 32px;
    }

    .pardeep {
        background-image: none;
        background-size: 53%, cover;
        background-repeat: no-repeat;
        background-position: left 5rem, center;
        margin: 4rem 0;
    }

    .cardsection {
        position: absolute;
        bottom: -77%;
        left: 6%;
    }

    .Related_Services {
        width: 100%;
        margin-top: 23rem;
    }

    .haveanyquestion {
        top: -33%;
    }

    .overview {
        overflow-x: hidden;
    }

    .howitwork {
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto;
    }

    .Think_to_know {
        background-image: none;
    }

    .halfCircleImage {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .OverViewBorder {
        border-right: none;
    }

    .How_We_Work_card h4 {
        font-size: 1rem;
    }

    .How_We_Work_card_Section h3 {
        font-size: 1.5rem;
    }

    .Currency_Trading_image {
        width: 100%;
        position: unset;
    }

    .Currency_Trading_text {
        margin-left: 3.5rem;
    }

    .What_We_Do span.carousel-control-next-icon {
        display: none;
    }

    .What_We_Do span.carousel-control-prev-icon {
        display: none;
    }

    .overview {
        padding-bottom: 3rem;
    }

    .pardeep {
        background-image: none;
        background-size: 53%, cover;
        background-repeat: no-repeat;
        background-position: left 5rem, center;
        margin: 4rem 0;
    }

    .cardsection {
        position: absolute;
        bottom: -68%;
        left: 1%;
    }

    .Related_Services {
        width: 100%;
        margin-top: 25rem;
    }

    .How_We_Work_card_Section {
        width: 15rem;
        height: 200px;
    }

    /* .Currency_Trading_image {
        width: 100%;
        height: auto;
        top: 12%;
        z-index: 1;
    } */

    .upperNavbar.container {
        display: none;
    }

    .Currency_Trading_res {
        height: auto;
    }

    .Currency_Trading {
        padding-bottom: 2rem;
    }

    .haveanyquestion {
        top: -32%;
    }

    .overview {
        overflow-x: hidden;
    }

    .howitwork {
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto;
    }

    .Think_to_know {
        background-image: none;
    }

    .halfCircleImage {
        display: none;
    }

    .thinkaboutcrousel {
        padding: 5rem;
    }
}

@media (min-width: 320px) and (max-width: 576px) {
    .OverViewBorder {
        border-right: none;
    }

    .Currency_Trading_image {
        width: 100%;
        height: auto;
        top: 30%;
        z-index: 1;
        left: 0;
        position: unset !important;
    }

    .Currency_Trading_text {
        margin-left: 3.5rem;
    }

    .Currency_Trading {
        padding-top: 0rem;
    }

    .thinkaboutcrousel {
        padding: 3rem;
    }

    .overview {
        padding-bottom: 3rem;
    }

    .pardeep {
        background-image: none;
        background-size: 53%, cover;
        background-repeat: no-repeat;
        background-position: left 5rem, center;
        margin: 4rem 0;
    }

    .cardsection {
        position: absolute;
        bottom: -156%;
        left: 11%;
    }

    .Related_Services {
        width: 100%;
        margin-top: 31rem;
    }

    .Currency_Trading_res {
        height: auto !important;
    }

    .Currency_Trading {
        padding-bottom: 2rem;
    }

    .haveanyquestion {
        top: -19%;
    }

    h1.Crouserheading {
        font-size: 20px !important;
    }

    /* .Currency_Trading_image {
        width: 100%;
        height: auto;
        top: 12%;
        z-index: 1;
    } */

    .upperNavbar.container {
        display: none;
    }

    .Related_Services {
        width: 100%;
        margin-top: 55rem;
    }

    .overview {
        overflow-x: hidden;
    }

    .howitwork {
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto;
    }

    .Think_to_know {
        background-image: none;
    }

    .HeaderIconsLink {
        margin: 10px 0px 10px 0px;
        align-items: center;
        vertical-align: center !important;
        display: flex;
        justify-content: center;
    }

    .halfCircleImage {
        display: none;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    .How_We_Work_card {
        position: relative;
        display: flex;
        justify-content: center;
        top: 4rem;
        left: -0.6rem;
        padding-left: 0rem;
    }

    .cardsection {
        position: absolute;
        bottom: -51px;
        bottom: -163%;

    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .cardsection {
        position: absolute;
        bottom: -166%;
        left: 11%;
    }
}

@media (min-width: 500px) and (max-width: 577px) {
    .cardsection {
        position: absolute;
        bottom: -197%;
        left: 23%;
    }
}

@media (min-width: 576px) and (max-width: 577px) {
    .cardsection {
        position: absolute;
        bottom: -18rem;
        left: 0%;
    }
}

.navbar-toggler.collapsed {
    filter: invert(9);
}

.site_header.header-not-scrolled .navbar-toggler.collapsed {
    filter: unset !important;
}

.Footer a:hover {
    color: white;
}
.border-right-lg-up {
  border-right: 1px solid #474646;
}

.header-not-scrolled .nav-link:focus, .nav-link:hover {
    color: #0094b9 !important;
}
.header-scrolled .nav-link:focus, .nav-link:hover {
    /* color: rgb(255 255 255) !important; */
}
