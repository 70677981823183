@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

body {
    overflow-x: hidden !important;
    font-family: "Karla", sans-serif;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: #000 !important;
    background-color: #ffffff !important;
    border-color: #0094b9 !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    color: #000;
    pointer-events: none;
    background-color: #ffffff;
    border-color: #0094b9;
    opacity: var(--bs-btn-disabled-opacity);
}

.header-scrolled .nav-link.show {
    color: white;
}

.font-gilory-m {
    font-family: 'Gilroy-Medium', sans-serif !important;
}

h5 {
    font-family: 'Gilroy-Medium', sans-serif !important;
}

header.site_header {
    position: relative;
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: #0094b9;
    border-color: #0094b9;
}

p {
    font-family: "Karla", sans-serif;
}

a {
    text-decoration: none;
    color: unset;
}

h2 {
    font-size: 2rem;
    font-family: 'Gilroy-Medium', sans-serif !important;
}

h3 {
    font-size: 30px;
    /* font-weight: 300; */
    font-family: 'Gilroy-Medium', sans-serif !important;
}

.lableColor {
    color: #7d7b7c;
}

header.site_header {
    background-color: #fff;
    /* padding: 10px 0; */
    position: fixed;
    top: 0;
    width: 100%;
    /* overflow-x: hidden; */
    left: 0;
    right: 0;
    margin: 0px auto;
    z-index: 99;
}

header.site_header.header-scrolled {
    top: -92px;
    left: 0;
    right: 0;
    z-index: 2;
    color: white;
    background: #ffffff00;
    transform: translateY(90px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    /* box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1); */
    -moz-transition: transform 500ms ease, background 500ms ease;
    -ms-transition: transform 500ms ease, background 500ms ease;
    -o-transition: transform 500ms ease, background 500ms ease;
    position: fixed;
    /* -webkit-backdrop-filter: blur(10px); */
    /* backdrop-filter: blur(10px); */
    /* padding: 5px 0; */
}

header.site_header.header-not-scrolled {
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}

a.headerlink {
    text-decoration: none;
    color: unset;
}

p {
    font-size: 15px;
    line-height: 2;
}

h1 {
    font-size: 3rem;
    /* font-weight: 700; */
    font-family: 'Gilroy-Bold', sans-serif;
}

.HeaderIconsLink {
    margin: 10px 30px 10px 0px;
    align-items: center;
    vertical-align: center;
}

.primaryButton {
    background-color: #0194B9;
    color: white;
    border-color: #0194B9;
    width: 100px;
    font-weight: 600;
    margin-right: 10px;
    padding: 11px 10px;
}

.primaryButton:hover {
    background-color: white;
    color: #000000;
    border-color: #0194B9 !important;
}

a.primaryButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #0194B9;
}

.secondaryButton {
    background-color: white;
    color: black;
    border: white;
    width: 100px;
    font-weight: 600;

}

a.secondaryButton {
    background-color: white;
    color: black;
    border: white;
    width: 100px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    transition: top 0.3s ease-in-out;
}

a.secondaryButton:hover {
    background-color: #0194B9;
    transition: top 0.3s ease-in-out;
    color: white;
}

.header-not-scrolled a.secondaryButton {
    border: 1px solid #0194B9;
}

.header-not-scrolled a.primaryButton:hover {
    border: 1px solid #0194B9 !important;
}

.HeaderNavLink {
    margin-right: 25px;
}

.upperNavbar {
    transition: top 0.3s ease-in-out;
}

.upperNavbar-hidden {
    position: relative;
    /* Adjust as necessary */
    top: -100px;
    /* Ensure this matches the height of your upper navbar */
    display: none;
}

.bg-body-tertiary {
    position: sticky;
    top: 0;
    z-index: 1020;
    /* Ensure it's above other content */
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: #f8f9fa00 !important;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

/* .carousel-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
} */
.home-section {
    width: 100%;
}

.carousel-caption {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%);
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
    width: 70%;
}

.Carousel-Button {
    /* margin-right: 20px; */
    width: 180px;
    padding: 10px;
    font-size: 18px;
    /* background: #0094b9; */
    background: white;
    /* border: 1px solid 0094b9; */
    border: 1px solid white;
    color: black;
    transition: top 0.3s ease-in-out;
}

.Carousel-Button-tow {
    /* margin-right: 20px; */
    width: 180px;
    padding: 10px;
    font-size: 18px;
    /* background: #0094b9; */
    background: white;
    /* border: 1px solid 0094b9; */
    border: 1px solid white;
    color: black;
    transition: top 0.3s ease-in-out;
}

.Carousel-Button:hover {
    background: #0094b9;
    /* border: 1px solid; */
    border: 1px solid #0094b9;
    color: white;
    transition: top 0.3s ease-in-out;
}

.Carousel-Button:hover img {
    filter: invert(100%);
}

.Carousel-Button-tow:hover {
    background: #0094b9;
    /* border: 1px solid; */
    border: 1px solid white;
    transition: top 0.3s ease-in-out;
}

.CarouselTow-Button {
    margin-right: 20px;
    width: 180px;
    padding: 10px;
    font-size: 18px;
    background: transparent;
    border: 1px solid;
    transition: top 0.3s ease-in-out;
}

.CarouselTow-Button:hover {
    background: #0094b9;
    border: 1px solid #0094b9;
    transition: top 0.3s ease-in-out;
}

.primaryButtonbtn {
    margin-right: 20px;
    width: 180px;
    padding: 10px;
    font-size: 18px;
    /* background: #0094b9; */
    background: white;
    /* border: 1px solid 0094b9; */
    border: 1px solid white;
    color: black;
    transition: top 0.3s ease-in-out;
}

.primaryButtonbtn:hover {
    background: #0094b9;
    border: 1px solid #0094b9;
    transition: top 0.3s ease-in-out;
}

.primaryButtonbtn:hover img,
.Carousel-Button-tow:hover img {
    filter: invert(1);
    transition: top 0.3s ease-in-out;
}

a.HeaderNavLink.nav-link {
    color: #ffffffa6;
}

.nav-link {
    display: block;
    padding: 8px 0px !important;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #ffffffa6;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.header-not-scrolled a.headerlink.HeaderNavLink.active {
    color: #0094b9;
}

a.headerlink.HeaderNavLink.active {
    color: white;
}

.header-not-scrolled a.headerlink.HeaderNavLink {
    color: #000000a6;
}

.header-not-scrolled a.headerlink.HeaderNavLink:hover {
    color: #0094b9;
}

.header-not-scrolled .nav-link {
    color: #000000a6;
}

a.headerlink.HeaderNavLink:hover {
    color: white;
}

a.HeaderNavLink.nav-link:hover {
    color: white;
}

.header-not-scrolled a.HeaderNavLink.nav-link:hover {
    color: #0094b9;
}

.header-scrolle .nav-link:focus,
.nav-link:hover {
    color: white;
}

.header-not-scrolled .nav-link:focus,
.header-not-scrolled .nav-link:hover {
    color: #0094b9 !important;
}


.header-not-scrolled .navbar-nav .nav-link.active,
.header-not-scrolled .navbar-nav .nav-link.show {
    color: rgb(0 148 185);
}

.carousel-control-prev-icon {
    background-image: url("../public/img/Group 23562.png");
}

.carousel-control-next-icon {
    background-image: url("../public/img/Group 23563.png")
}

.bottom-card {
    background-color: #fff;
    z-index: 9;
    position: relative;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border: none;
    height: 100%;
    /* width: 18rem */
}

.bottom-card:hover {
    background-color: #0094b9;
    color: white;
}

.home-card {
    margin-top: -51px;
    width: 100%;
}

img.cardImage {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.title {
    color: #0094b9;
}

.overview {
    background: url("../public/img/right-bg.png");
    /* background: url("../public/img/Group 23716.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 10rem;
    width: 100%;
    /* overflow-x: hidden; */
}

/* .ourmissiondiv {
    width: 100%;
    background: #0194B9;
    border-radius: 0px 24px 24px 0px;
} */
.ourmissiontitle {
    padding-left: 30px;
    /* // marginLeft: "35px"; */
    width: 60%;
}

.Button {
    margin-right: 20px;
    width: 180px;
    padding: 10px;
    font-size: 18px;
    /* background: #0094b9; */
    background: white;
    border: 1px solid 0094b9;
    border: none;
    color: black;
}

.Button:hover {
    background: #0094b9;
    border: 1px solid white;
    border: none;
}

.ourmission {
    position: relative;
    width: 100%;
    /* overflow: hidden; */
    /* Adjust as needed */
    min-height: 450px;
    height: 100%;
    /* Adjust as needed */
    /* background-image: linear-gradient(to right,#0194B9 77%, transparent 50%); */
}

.ourmission::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 77%;
    height: 100%;
    background: #0194B9;
    /* Your desired background */
    border-top-right-radius: 50px;
    /* Adjust as needed */
    border-bottom-right-radius: 10px;
    /* Adjust as needed */
}

.mission-crousel {
    position: absolute;
    right: 0px;
    top: 21%;
}

.mission-crouselimage {
    position: absolute;
    right: 35%;
    top: -2%;
    z-index: 9;
}

/* .afterBefore {
    width: 100%;
} */
.afterBefore {
    width: 100%;
    margin: 0px 0px 12px 0px;
    margin-top: 0px;
    margin-bottom: 12px;
}

.afterBefore::after {
    background-color: #313131;
    content: " ";
    font-size: 5px;
    position: relative;
    transform: translate(-50%);
    width: 20%;
    height: 5px;
    display: block;
    left: 50%;
}

/* .afterBefore:after {

    background-color: #313131;
    padding: 0 123px;
    content: " ";
    font-size: 5px;
    position: relative;
    bottom: 10px;
    left: 41%;
    transform: translate(-50%);
    /* display: flex; */
/* align-items: center;
    justify-content: center;
} */

.factorcard {
    width: 100%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 11px;
}

.factorCardTitle {
    width: 94%;
    border-left: 2px solid #a0d9e5;
    padding: 5px 10px;
    transition: all .3s ease-in-out;
}

.factorcard:hover {
    background: #a0d9e5;
    /* border-left: 2px solid #a0d9e5; */
    /* border-radius: 7px; */
    transition: all .3s ease-in-out;
}

.factorcard:hover .factorCardImagee {
    /* -webkit-filter: brightness(9); */
    filter: contrast(1) brightness(0.5);
    transition: all .3s ease-in-out;
}

.mainfactor {
    background: url("../public/img/left-bg.png");
    /* background: url("../public/img/BG-Fox trade key.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    position: relative;
}

.halfCircleImage {
    position: absolute;
    left: 29%;
    bottom: -52%;
}

.halfCircleImageLeft {
    position: absolute;
    left: 0%;
    bottom: 0%;
}

.howitwork {
    background: url("../public/img/pardeep.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    width: 100%;
    margin-top: 8rem;
}

.factorCardImage {
    position: absolute;
    top: -18px;
    right: -19px;
}

img.howitworkImage {
    position: absolute;
    top: -14%;
    right: 19%;
}

.whatwedoImage {
    border-radius: 7px;
}

.readMore {
    background: #0094b9;
    color: white;
    border: 1px solid #0094b9;
}

.readMore:hover {
    background: white;
    color: black;
    border: 1px solid #0094b9;
}

.whatwedocard {
    width: 22rem;
    border: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    position: absolute;
    right: 0px;
    top: 50%;

    transform: translate(0%, -50%);
    min-height: 387px;
}

.anyQuestion {
    background-color: #0094b9;
    background-image: url("../public/img/Group 23780.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    border-radius: 7px;
    width: 90%;
}

.Footer {
    width: 100%;
    background-color: #000000;
    position: relative;
}

.Subscribe {
    background: #0094B9;
    border: 1px solid #0094B9;
    color: white;
    padding: 10px;
    width: 250px;
    font-size: 20px;
    justify-content: center;
}

.Subscribe:hover {
    background: white;
    border: 1px solid #0094b9;
    color: #000000;
    transition: all .3s ease-in-out;
}

.SubscribeInput {
    width: 57%;
    background: transparent;
    color: white;
    border-color: #ffffff38;
    padding: 14px;
}

.Subscribe:hover img {
    filter: invert(100%);
    transition: all .3s ease-in-out;
}

.haveanyquestion {
    position: relative;
    width: 100%;
    bottom: -3rem;
    z-index: 1;
}

.Contact_Us:hover {
    background-color: #0094B9;
    border-color: white !important;
    transition: all .3s ease-in-out;
    color: white;
}

.Contact_Us:hover img {
    filter: invert(100%);
    transition: all .3s ease-in-out;
}

.about-us {
    width: 100%;
    background-image: url("../public/img/Aboutus/Group 23737.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Crypto {
    width: 100%;
    background-image: url("../public/img/Group 23740.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.realEstate {
    width: 100%;
    background-image: url("../public/img/Group 23754.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Commodity {
    width: 100%;
    background-image: url("../public/img/Group 23781.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Mutual_Funds {
    width: 100%;
    background-image: url("../public/img/Group 23761.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Equity_Trading {
    width: 100%;
    background-image: url("../public/img/Group 23763.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Contact_us {
    width: 100%;
    background-image: url("../public/img/Group 23766.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Investment_Currency_Trading {
    width: 100%;
    background-image: url("../public/img/Group 23783.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-text {
    position: absolute;
    border: 0px;
    width: 100%;
    bottom: 23%;
    padding: 15px;
}

img.about-image {
    position: absolute;
    bottom: 28%;
    left: 47%;
}

img.about-image-tow {
    position: absolute;
    bottom: 5%;
    left: 0px;
}

ul.whoweareList {
    list-style: none;
    padding: 0px;
}

.whoweare {
    width: 100%;
    overflow: hidden;
    /* background-image: url(../public/img/Mask Group 40@2x.png);
    background-repeat: no-repeat;
    background-size: cover; */
}

.aboutus {
    width: 100%;
    overflow: hidden;
}

.innnerTabsImage {
    background-color: black;
    border-radius: 0px 0px 0px 30px;
    width: 170px;
}

.innerTabContent {
    background-color: #0094b9;
    border-radius: 0px 7px 7px 0px;
    width: 100%;
}

.tab-content {
    padding: 0px;
    border-radius: 0px 7px 7px 30px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #0094b9;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: #0094b9;
}

.tabs .nav-link {
    color: black;
    font-size: 20px;
    font-weight: 600;
    width: 153px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    color: #0094b9;
    /* border-color: #0094b9; */
    /* border: none; */
}

.thinkToknow {
    width: 100%;
    overflow: hidden;
    background-image: url("../public/img/right-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.keyfactordiv {
    width: 100%;
    position: relative;
}

img.keyfactordivImage {
    /* position: absolute; */
    /* left: -22%; */
    /* top: 99px; */
    width: 100%;
    margin-top: -30px;
}

.number {
    background: #0194B9;
    width: 40px;
    /* text-align: center; */
    display: flex;
    border-radius: 50%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 5px solid #A8D2F4;
    color: #00556B;
    font-size: 20px;
    font-weight: 700;
}

.numbersection {
    margin-right: 43px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: -21px;
}

.numbersectionlast {
    margin-right: 43px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: -21px;
}

.numbersection::after {
    content: "";
    position: absolute;
    top: 26%;
    left: 49%;
    height: calc(100% + [ Adjustment]);
    width: 1px;
    background-color: #0194B9;
    z-index: 999;
    padding: 0px;
    height: 115px;
    z-index: 1;
}

/* Remove line from the last .numbersection element */
.numbersection:last-child::after {
    display: none;
}

.thinkaboutcrousel {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: 11px;
    box-shadow: 0px 20px 20px #00000005;

}

.getStarted {
    width: 100%;
    overflow: hidden;
    background-image: url("../public/img/Aboutus/Group 23779.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-bottom: 13rem !important; */
}

a.dropdown-item {
    border-bottom: 1px solid #ffffff52;
    color: #ffffffcc;
}

.dropdown-menu.show {
    background: #0094b9;
}

.dropdown-item:focus,
.dropdown-item:hover {
    /* background-color: #0094b9 !important; */
    color: white;
    text-decoration: none;
    background-color: #0194B9;
}
.dropdown-item:last-child{
    border: none !important;
}

/* .dropdown-item .active,
.dropdown-item:active {
    color: rgb(0, 0, 0);
    background-color: #0094b9 !important;
} */

/* .dropdown-item.active, .dropdown-item:active { */
    /* color: #0094b9;
    text-decoration: none; */
/* } */

.dropdown-item.active,
.dropdown-item:active {
    background: #0194B9;
    position: relative;
    color: white;
}

.dropdown-item.active::after {
    content: '';
    position: absolute;
    background: #fff;
    width: 25px;
    height: 5px;
    bottom: -3px;
    left: 19px;
    border-radius: 20px;
}


.Services {
    background: #0094b9;
    border-radius: 50px 0px 0px 0px;
}

.Services ul {
    list-style: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    padding-left: 24px;
}

.Services ul li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid;
    margin-top: 14px;
    padding-bottom: 10px;
    color: #ffffff7a;

}

.Services ul li:hover {
    color: white;
    cursor: pointer;
}

.Currency_Trading {
    background: url("../public/img/right-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 3rem;
    padding-bottom: 13rem;
    position: relative;
}

.How_We_Work_bg {
    background: url("../public/img/left-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* .Currency_Trading_image {
    /* position: absolute; */
/* left: 0px; */
/* width: 70%; */
/* height: 500px; */
/* top: 22%; */
/* z-index: -1; */
/* }  */

.Currency_Trading_image {
    position: absolute;
    left: -4rem;
    width: 134%;
    height: 500px;
    top: 30%;
    z-index: 0;
}

.About_This_Service {
    width: 100%;
    overflow: hidden;
}

.How_We_Work_background {
    background: url("../public/img/Group 23743.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem;
    position: relative;
    padding-bottom: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.How_We_Work_card {
    position: relative;
    bottom: -4rem;
}

.How_We_Work_card_image {
    width: 100%;
    position: absolute;
    top: -15px;
}

.cardsection {
    position: absolute;
    bottom: -51px;
    /* width: 100%;
    overflow-x: hidden; */
}

.cardsection .card {
    border: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.Related_Services {
    width: 100%;
    margin-top: 9rem;
}

.Group_card {
    width: 100%;
    height: 25.25rem;
    transition: transform 0.3s ease;
}

.hover-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #fff;
    /* Adjust text color as needed */
    font-size: 1.5rem;
    /* Adjust text size as needed */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.card-image-container:hover .hover-text {
    opacity: 1;
    /* Show the text on hover */
}

.card-hover {
    transition: transform 0.3s ease;
}

.card-hover:hover {
    transform: scale(1.05);
    /* Increase the size */
}

.card-image-container {
    position: relative;
    width: 100%;
    height: 25.25rem;
    /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Get_in_touch {
    width: 100%;
    margin-bottom: 3rem;
    background: url("../public/img/right-bg.png");
    /* background: url("../public/img/BG-Fox trade key.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    position: relative;
    min-height: 390px;
}

.Get_in_touch_section {
    /* position: absolute; */
    /* width: 100%; */
    border: 1rem;
}

img.bd_left_Image {
    position: absolute;
    right: -4rem;
    top: 6rem;
}

img.bd_right_Image {
    position: absolute;
    left: 0;
    bottom: 0rem;
}

.card {
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 11px;

}

.Contact_us_form {
    background-color: #0094b9;
    padding: 2rem;
    border-radius: 7px;
}

.LoginPage {
    background: url("../public/img/Group 23776@2x.png") no-repeat center center fixed;
    /* background-repeat: no-repeat; */
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.loginForm {
    background: rgba(255, 255, 255, 0.1);
    padding: 3rem;
    max-width: 550px;
    width: 100%;
    margin: auto;
    min-height: 500px;
    box-sizing: border-box;
}

.loginForm input#formBasicEmail {
    background: #ffffff0f;
    border: 1px solid #ffffff0f;
    color: white;
}

.custom-placeholder::placeholder {
    color: #ffffff4a;
    /* Change #999 to whatever color you prefer */
}

.SubscribeInput::placeholder {
    color: #575757 !important;
    text-align: center;
}

.Private-Police {
    width: 100%;
    background-image: url("../public/img/Group 23773@2x.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 90vh;
}

.Terms-Condition {
    width: 100%;
    background-image: url("../public/img/Group 23774@2x.png");
    /* Set your background image */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 90vh;
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #F8F6F6;
    box-shadow: none;
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: #F8F6F6;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}

.upperNavbar.container {
    /* border-bottom: 1px solid #ffffff42; */
}


/* LOADING CSS */
@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes spin3D {
    from {
        transform: rotate3d(.5, .5, .5, 360deg);
    }

    to {
        transform: rotate3d(0deg);
    }
}

@keyframes configure-clockwise {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes configure-xclockwise {
    0% {
        transform: rotate(45deg);
    }

    25% {
        transform: rotate(-45deg);
    }

    50% {
        transform: rotate(-135deg);
    }

    75% {
        transform: rotate(-225deg);
    }

    100% {
        transform: rotate(-315deg);
    }
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .25;
        transform: scale(.75);
    }
}

/* GRID STYLING */

.spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}


/* PULSE BUBBLES */

.pulse-container {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.pulse-bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3ff9dc;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}

.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}

.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

.Loader {
    width: 100%;
    height: 100vh;
    background-color: #1d2630;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HaveAnyQuestionSection {
    width: 100%;
}

.ourmission .carousel-inner {
    position: relative;
    width: 100%;
    overflow: visible;
}

.ourmission a.carousel-control-prev {
    position: absolute;
    left: 61%;
    top: 20px;
}

.ourmission .carousel-control-next {
    right: 20%;
    top: 20px !important;
}

.ourmission .carousel-control-next,
.ourmission .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 1;
    transition: opacity .15s ease;
}

.VisionImages {
    width: 37rem;
    height: 26rem;
    object-fit: cover;
}

.VisionIcons {
    width: 180px;
    height: auto;

    /* height: 260px; */
}

.textDiv {
    border-left: 7px solid black;
    padding-left: 30px;
    margin-left: 3rem;
}

.textDiv.keyfactorSectionTextdiv {
    margin-left: 5rem !important;
}

.divMargin {
    margin-left: 3rem;
}

.What_We_Do {
    width: 100%;
    position: relative;
}

img.uperimage-poss {
    position: absolute;
    top: 12rem;
}

.uperimage-poss-right {
    position: absolute;
    top: 26rem;
    right: 4rem;
}

/* .What_We_Do.overview {
    padding-bottom: 12rem !important;
} */

.AboutUsImage {
    width: 100%;
    height: 90%;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: rgb(255 255 255);
}

.How_We_Work_card_Section {
    width: 18rem;
    height: 200px;
}

.How_We_Work {
    width: 100%;
}

.password-field {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.custom-placeholder {
    padding-right: 30px;
    /* Ensure text doesn't overlap the icon */
}

.Crouserheading {
    font-weight: 600;
}

img.ImageSlider {
    position: absolute;
    top: -5rem;
    width: 22%;
    left: -7rem;
    min-height: auto !important;
    object-fit: cover !important;
}

.What_We_Do .carousel-control-next-icon {
    background-image: url("../public/img/Group 23590.png")
}

.What_We_Do .carousel-control-prev-icon {
    background-image: url("../public/img/Group 23589.png");
}

.What_We_Do span.carousel-control-next-icon {
    position: absolute;
    bottom: 2rem;
    right: 50%;
}

.What_We_Do span.carousel-control-prev-icon {
    position: absolute;
    right: -490% !important;
    bottom: 2rem;
    width: 40px;
    height: 40px;
}

h1.Crouserheading {
    font-size: 50px;
    font-family: 'Gilroy-Medium', sans-serif !important;
}

h4.Crouserheading {
    font-family: 'Gilroy-Light', sans-serif !important;
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 1;
    transition: opacity .15s ease;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.pardeep {
    background-image: url(../public/img/Aboutus/Group%2023736.png), url(../public/img/right-bg.png);
    background-size: 53%, cover;
    background-repeat: no-repeat;
    background-position: left 9rem, center;
    padding: 4rem 0;
}

.Think_to_know {
    background-image: url(../public/img/Aboutus/Group 23738.png);
    background-size: 56%, cover;
    background-repeat: no-repeat;
    background-position: right 5rem;
    /* margin: 4rem 0; */
}

.home-section .carousel-fade .carousel-item img {
    min-height: 600px;
    object-fit: cover;
}

.counter-Span {
    font-size: 2rem;
    font-weight: 100 !important;
    font-family: 'Gilroy-Light', sans-serif !important;
}

.tabs {
    position: relative;
    width: 100%;
}

img.Mask_Group {
    position: absolute;
    right: 0;
    bottom: 2rem;
    width: 141px;
}

img.keyLeftfactordivImage {
    position: absolute;
    top: 0rem;
    width: 64px !important;
}

.Currency_Trading_text {
    margin-left: 5.5rem;
}

.Currency_Trading_res {
    position: relative;
}

.OverViewBorder {
    border-right: 2px solid #7070705e;
}

.OverViewBorder {
    min-height: 200px
}

.divMarginHeignt {
    min-height: 200px;
}

.keyfactorSection .carousel-control-next,
.keyfactorSection .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 0;
    color: #000;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 1;
    transition: opacity .15s ease;
}

.keyfactorSection .carousel-control-next-icon {
    background-image: url("../public/img/Group 23590.png")
}

.keyfactorSection .carousel-control-prev-icon {
    background-image: url("../public/img/Group 23589.png");
}

.keyfactorSection span.carousel-control-prev-icon {
    position: relative;
    right: -50rem;
}

.keyfactorSection span.carousel-control-next-icon {
    position: relative;
    right: 2rem;
}

a.headerlink.HeaderNavLink {
    display: flex;
    align-items: center;
    color: #ffffffa6;
}

a.Carousel-Button.Contact_Us {
    border-radius: 7px;
    text-align: center;
}


a.Carousel-Button {
    border-radius: 7px;
    text-align: center;
}

.dropdown-menu {
    /* --bs-dropdown-link-active-color: #000000 !important; */
    padding: 0;
}